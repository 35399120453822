import {
  EShomeVerticalsRouting,
  FRhomeVerticalsRouting,
  PThomeVerticalsRouting,
} from 'cross-config/localization/routes';

export const esRouting = {
  location: 'localizacion',
  features: 'caracteristicas-vivienda',
  exteriorOrInterior: 'caracteristicas-exterior',
  size: 'caracteristicas-superficie',
  spaces: 'caracteristicas-espacios',
  extraSpaces: 'caracteristicas-extras',
  status: 'estado',
  isInhabited: 'estado-habitada',
  rented: 'estado-alquiler',
  reasonToSell: 'estado-motivo',
  confirmation: 'datos-contacto',
  confirmationSMS: 'datos-contacto-codigo',
  contact: 'contacto',
  outOfReach: 'fuera-zona',
  form: 'formulario',
  home: EShomeVerticalsRouting,
};

export const frRouting = {
  location: 'localisation',
  features: 'caracteristiques-logement',
  exteriorOrInterior: 'caracteristiques-exterieur',
  size: 'caracteristiques-surface',
  spaces: 'caracteristiques-espaces',
  extraSpaces: 'caracteristiques-supplementaires',
  status: 'etat',
  isInhabited: 'etat-occupee',
  rented: 'état-location',
  whenToSell: 'état-souhaitez',
  reasonToSell: 'etat-motif',
  confirmation: 'donnees-contact',
  confirmationSMS: 'donnees-contact-code',
  contact: 'contact',
  outOfReach: 'hors-zone',
  form: 'formulaire',
  home: FRhomeVerticalsRouting,
};

export const ptRouting = {
  location: 'lugar',
  features: 'caracteristicas-habitacao',
  exteriorOrInterior: 'caracteristicas-exteriores',
  size: 'caracteristicas-superficie',
  spaces: 'caracteristicas-espacos',
  extraSpaces: 'caracteristicas-extras',
  status: 'estado',
  isInhabited: 'estado-habitat',
  rented: 'estado-aluguer',
  whenToSell: 'estado-razao',
  reasonToSell: 'estado-razao',
  confirmation: 'dados-contacto',
  confirmationSMS: 'dados-contacto-codigo',
  contact: 'contacto',
  outOfReach: 'fora-zona',
  form: 'formulario',
  home: PThomeVerticalsRouting,
};
