export const getItemFromSessionStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    const { sessionStorage } = window;
    const value = sessionStorage?.getItem(key);
    return value ? JSON.parse(value) : null;
  }
};

export const setItemToSessionStorage = <T>(key: string, value: T) => {
  if (typeof window !== 'undefined') {
    const { sessionStorage } = window;
    sessionStorage?.setItem(key, JSON.stringify(value));
  }
};
