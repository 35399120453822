import { AvailableLanguages } from 'cross-config/localization/config';
import { setPropertyRecursive } from 'cross-config/utils/setPropertyRecursive';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo, useState } from 'react';
import { basePathRoutes } from '@hooks/useRoutesI18n/useRoutesI18n';

import { Property, SetPropertyValueProps } from '../modules/acquisitions/types';
import {
  AcquisitionsContextProps,
  AcquisitionsContextProviderProps,
  Step,
} from './AcquisitionsContext.types';
import { propertyDefaultData, stepDefaultData } from './utils';

export const AcquisitionsContext = React.createContext<AcquisitionsContextProps>({
  property: propertyDefaultData,
  step: stepDefaultData,
  handleStep: () => null,
  setPropertyValue: () => null,
  resetContextValues: () => null,
});

const AcquisitionsContextProvider = ({ children }: AcquisitionsContextProviderProps) => {
  const [property, setProperty] = useState<Property>(propertyDefaultData);
  const router = useRouter();
  const [step, setStep] = useState<Step>(stepDefaultData);

  const setPropertyValue = useCallback((objValue: SetPropertyValueProps) => {
    const keyNames = Object.keys(objValue) as Array<keyof SetPropertyValueProps>;
    keyNames.forEach((keyName) => {
      const value = objValue[keyName];
      setProperty((prevProperty) => setPropertyRecursive(keyName, value, prevProperty));
    });
  }, []);

  const handleStep = useCallback(
    (step: Partial<Step>) => {
      setStep((prevStep) => ({
        number: step.number ?? prevStep.number,
        subStep: step.subStep ?? prevStep.subStep,
        percentage: step.percentage ?? prevStep.percentage,
      }));
      window && window.dataLayer && window.dataLayer.push({ event: 'pageview' });
      step.routeAs &&
        router.push(
          `/${basePathRoutes[router.locale as AvailableLanguages]}/${step.routeAs}`,
          undefined,
          {
            shallow: true,
          }
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step]
  );

  const resetContextValues = useCallback(() => {
    setProperty(propertyDefaultData);
    setStep(stepDefaultData);
  }, []);

  const contextValue = useMemo(
    () => ({
      property,
      setPropertyValue,
      step,
      handleStep,
      resetContextValues,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step, property, setPropertyValue]
  );

  return (
    <AcquisitionsContext.Provider value={contextValue}>{children}</AcquisitionsContext.Provider>
  );
};

export default AcquisitionsContextProvider;
