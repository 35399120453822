import type { AppProps } from 'next/app';
import { appWithTranslation, UserConfig } from 'next-i18next';
import Script from 'next/script';
import getConfig from 'next/config';
import nextI18nConfig from '../../next-i18next.config';
import React, { useEffect } from 'react';
import { setItemToSessionStorage } from 'cross-config/utils/sessionStorage';
import { StorageKeys } from 'cross-config/generic/storageKeys';
import { ThemeProvider } from 'styled-components';
import { defaultTheme, GlobalStyle } from '@clikaliatech/clikalia-ui';
import AcquisitionsContextProvider from '@contexts/AcquisitionsContext';
import { clikaliaApiClient } from 'clikalia-communication';

import '../static/css/app-styles.css';
import { LocalizationContextProvider } from 'clikalia-web-components/src';
import { useRouter } from 'next/router';
import ScreenLoadingFromRoute from '@components/common/ScreenLoadingFromRoute/ScreenLoadingFromRoute';

const {
  publicRuntimeConfig: { version },
} = getConfig();

const App = ({ Component, pageProps }: AppProps) => {
  const { locale, defaultLocale, query } = useRouter();
  clikaliaApiClient.setLocaleHeader(locale);

  useEffect(() => {
    window.clikalia = {
      app: {
        currentVersion: version,
      },
    };
  }, []);

  useEffect(() => {
    if (query?.utm_medium) {
      setItemToSessionStorage(StorageKeys.utmMedium, query.utm_medium);
    }
    if (query?.utm_source) {
      setItemToSessionStorage(StorageKeys.utmSource, query.utm_source);
    }
    if (query?.utm_campaign) {
      setItemToSessionStorage(StorageKeys.utmCampaign, query.utm_campaign);
    }
  }, [query.utm_campaign, query.utm_medium, query.utm_source]);
  return (
    <>
      {process.env.NODE_ENV !== 'development' && [
        <Script
          id="gtm-script"
          key="gtm-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.GTM-PZZQB8P}'); `,
          }}
        ></Script>,
      ]}
      <LocalizationContextProvider locale={locale} defaultLocale={defaultLocale}>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyle />
          <AcquisitionsContextProvider>
            <Component {...pageProps} />
          </AcquisitionsContextProvider>
          <ScreenLoadingFromRoute />
        </ThemeProvider>
      </LocalizationContextProvider>
    </>
  );
};

// Cast needed by bug in nextjs type -> https://github.com/i18next/next-i18next/issues/2049#issuecomment-1348789516
export default appWithTranslation(App, nextI18nConfig as UserConfig);
