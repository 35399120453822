import React, { useEffect, useMemo, useState } from 'react';
import {
  AvailableLanguagesArray,
  browserLanguageToI18nLanguageMap,
} from 'cross-config/localization/config';
import { splitLocale } from 'cross-config/localization/splitLocale';
import { getCountryCode, getUrlLocale } from 'cross-config/localization/utils';
import {
  LocalizationContextProps,
  LocalizationContextProviderProps,
} from './LocalizationContext.types';
import Cookie from 'js-cookie';

export const LocalizationContext = React.createContext<LocalizationContextProps>({
  location: { city: '', latLong: '', region: '' },
  countryCode: 'ES',
  languagePredefined: 'ES',
  localeFormatted: 'es',
  urlLocale: '',
});

const LocalizationContextProvider = ({
  locale = 'es-ES',
  defaultLocale = 'es-ES',
  children,
}: LocalizationContextProviderProps) => {
  const countryCode = getCountryCode();

  const cookie = Cookie.get('NEXT_LOCALE');

  const [localeFormatted, setLocaleFormatted] = useState(splitLocale(locale) ?? 'es');
  const [language, setLanguage] = useState(cookie);
  const [urlLocale, setUrlLocale] = useState(getUrlLocale({ locale, defaultLocale }));

  useEffect(() => {
    setLocaleFormatted(splitLocale(locale) ?? 'es');
    setLanguage(locale ?? 'ES');
  }, [locale]);

  useEffect(() => {
    setUrlLocale(getUrlLocale({ locale, defaultLocale }));
  }, [locale, defaultLocale]);

  const region = Cookie.get('region') || '';
  const city = Cookie.get('city') || '';
  const latLong = Cookie.get('latLong') || '';

  const location = useMemo(() => ({ region, city, latLong }), [city, latLong, region]);

  useEffect(() => {
    if (!language) {
      const urlContainsFriendlyLanguage = AvailableLanguagesArray.find((lang) =>
        window.location.href.includes(`/${lang}.`)
      );

      const Preferedlanguage = urlContainsFriendlyLanguage
        ? urlContainsFriendlyLanguage
        : browserLanguageToI18nLanguageMap[navigator.language];

      setLanguage(Preferedlanguage);
      Cookie.set('NEXT_LOCALE', Preferedlanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = useMemo(
    () => ({
      languagePredefined: language,
      countryCode,
      location,
      localeFormatted,
      urlLocale,
    }),
    [location, language, localeFormatted, countryCode, urlLocale]
  );

  return (
    <LocalizationContext.Provider value={contextValue}>{children}</LocalizationContext.Provider>
  );
};

export default LocalizationContextProvider;
