import { useEffect, createContext, useContext, useState, useCallback } from 'react';
import {
  UserLoginRequest,
  UserLoginResponse,
  UserLogoutRequest,
  UserLogoutResponse,
} from '@clikaliatech/commons-clikalia-website';

import ClikaliaAuthApiClient from '../Client/ClikaliaAuthApiClient';
import {
  AuthProviderStatus,
  ClikaliaAuthContextProps,
  ClikaliaAuthProviderProps,
  GetPrivateInfoResponse,
} from './ClikaliaAuthProvider.types';

let clikaliaAuthApiClient: ClikaliaAuthApiClient;

const ClikaliaAuthContext = createContext<ClikaliaAuthContextProps | null>(null);

const ClikaliaAuthProvider = ({ baseUrl, children, locale }: ClikaliaAuthProviderProps) => {
  const [status, setStatus] = useState<AuthProviderStatus>({
    isAuthenticated: false,
    isLoading: true,
    user: undefined,
  });

  const getUser = useCallback(async () => {
    const getPrivateInforesponse = await clikaliaAuthApiClient.post<GetPrivateInfoResponse>({
      url: '/user/get-private-info',
      data: {},
    });
    const user = getPrivateInforesponse.data;
    setStatus({ isAuthenticated: true, isLoading: false, user });
  }, []);

  const signIn: ClikaliaAuthContextProps['signIn'] = useCallback(
    async (body) => {
      const { email, password, remember } = body;

      await clikaliaAuthApiClient.post<UserLoginResponse, UserLoginRequest>({
        url: '/user/login',
        data: { email, password, remember },
      });
      await getUser();
    },
    [getUser]
  );

  const removeAuth = useCallback(() => {
    setStatus({ isAuthenticated: false, isLoading: false, user: undefined });
  }, []);

  const logout = useCallback(async () => {
    await clikaliaAuthApiClient.post<UserLogoutResponse, UserLogoutRequest>({
      url: '/user/logout ',
      data: {},
    });
    removeAuth();
  }, [removeAuth]);

  const init = useCallback(async () => {
    try {
      await getUser();
    } catch (error) {
      console.warn('Failed when get private info: ', (error as Error).message);
    }
  }, [getUser]);

  useEffect(() => {
    if (!clikaliaAuthApiClient) {
      clikaliaAuthApiClient = new ClikaliaAuthApiClient(baseUrl);
      clikaliaAuthApiClient.setLocaleHeader(locale);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  useEffect(() => {
    document.addEventListener('invalid-auth-token', removeAuth);
    return () => {
      document.removeEventListener('invalid-auth-token', removeAuth);
    };
  }, [removeAuth]);

  const providerValues: ClikaliaAuthContextProps = {
    isAuthenticated: status.isAuthenticated,
    isLoading: status.isLoading,
    user: status.user,
    refreshUser: getUser,
    signIn,
    logout,
  };

  return (
    <ClikaliaAuthContext.Provider value={providerValues}>{children}</ClikaliaAuthContext.Provider>
  );
};

const useClikaliaAuth = () => {
  const clikaliaAuthContext = useContext(ClikaliaAuthContext);
  if (!clikaliaAuthContext) {
    throw new Error('Must be used within a ClikaliaAuthProvider');
  }

  return clikaliaAuthContext;
};

export { clikaliaAuthApiClient, ClikaliaAuthProvider, useClikaliaAuth };
