import { AvailableDomainCountries, availableDomainCountries, AvailableCountries } from './config';

const getDomainExtension = (domain: string) => {
  return domain.match(/\.[a-z]+$/)?.[0]?.replace('.', '') as AvailableDomainCountries | undefined;
};

const lifeguardCountryCodeFromHost = (host?: string) => {
  let domainExtension: AvailableDomainCountries | undefined;

  if (host) {
    domainExtension = getDomainExtension(host);
  }
  if (typeof location !== 'undefined') {
    domainExtension = getDomainExtension(location.hostname);
  }

  if (domainExtension && availableDomainCountries.includes(domainExtension)) {
    return domainExtension.toUpperCase() as AvailableCountries;
  }
  return 'ES';
};

export const getCountryCode = (host?: string) => {
  return (
    (process.env.NEXT_PUBLIC_COUNTRY_CODE as AvailableCountries) ||
    lifeguardCountryCodeFromHost(host)
  );
};

export const getCountryPhonePrefix = (country: AvailableCountries) => {
  const prefix = {
    ES: '+34',
    FR: '+33',
    PT: '+351',
  };
  return prefix[country];
};

export const getUrlLocale = ({
  locale,
  defaultLocale,
}: {
  locale?: string;
  defaultLocale?: string;
}) => (locale === defaultLocale ? '' : `/${locale}`);
