import { Property } from '../modules/acquisitions/types';
import { Step } from './AcquisitionsContext.types';

export const stepDefaultData: Step = {
  number: 1,
  subStep: 0,
  percentage: 1,
};

export const propertyDefaultData: Property = {
  basicCharacteristics: {
    bathrooms: 1,
    bedrooms: 1,
    buildYear: 0,
    liftAvailable: false,
    squareMeters: 0,
    typology: undefined,
    windowLocation: undefined,
  },
  extraCharacteristics: {
    balcony: false,
    garage: false,
    doorman: false,
    garden: false,
    storageRoom: false,
    swimmingPool: false,
    terrace: false,
  },
  location: {
    block: '',
    cadastralReference: '',
    country: '',
    city: '',
    door: '',
    floor: '',
    numFloors: '',
    lat: '',
    long: '',
    number: '',
    postalCode: '',
    province: '',
    stair: '',
    street: '',
  },
  sellingInformation: {
    expectedPrice: undefined,
    idealistaLink: '',
    inhabitedState: undefined,
    propertyState: 'goodState',
    sellingReason: undefined,
  },
  userInformation: {
    email: '',
    name: '',
    phoneNumber: '',
    surname: '',
    language: '',
  },
  policyChecks: {
    acceptedPolicy: false,
    marketingCheckAccepted: false,
  },
};
