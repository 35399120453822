import { CountryIsoCode2 } from '@clikaliatech/commons-clikalia-website';

export type AvailableLanguagesFormatted = 'ES' | 'FR' | 'PT' | 'EN' | 'CA';

export const availableDomainCountries = ['es', 'fr', 'pt'] as const;
export const AvailableLanguagesArray = [
  'es-ES',
  'ca-ES',
  'en-ES',
  'fr-FR',
  'en-FR',
  'en-PT',
  'pt-PT',
] as const;

export type AvailableDomainCountries = (typeof availableDomainCountries)[number];
export type AvailableCountries = Uppercase<AvailableDomainCountries>;
export type AvailableLanguages = (typeof AvailableLanguagesArray)[number];

export const browserLanguageToI18nLanguageMap: Record<string, string> = {
  'es-ES': 'es-ES',
  'ca-ES': 'es-CA',
  'en-ES': 'en-ES',
  'gp-ES': 'en-ES',
  'fr-FR': 'fr-FR',
};

export const I18nLanguageMapToFriendlyLanguage: Record<string, AvailableLanguagesFormatted> = {
  'es-ES': 'ES',
  'ca-ES': 'CA',
  'en-ES': 'EN',
  'fr-FR': 'FR',
  'en-FR': 'EN',
  'pt-PT': 'PT',
  'en-PT': 'EN',
};

export const I18nLanguageMapToCountry: Record<string, string> = {
  'es-ES': 'ES',
  'ca-ES': 'ES',
  'en-ES': 'ES',
  'fr-FR': 'FR',
  'en-FR': 'FR',
  'pt-PT': 'PT',
  'en-PT': 'PT',
};

export const Countries: Record<string, CountryIsoCode2> = {
  es: 'ES',
  mx: 'MX',
  pt: 'PT',
  fr: 'FR',
};

export interface LanguageWithFlag {
  label: string;
  value: AvailableLanguages;
  flag: string;
  svg?: boolean;
}

const languagesWithFlagsES: Array<LanguageWithFlag> = [
  {
    label: 'ES',
    value: 'es-ES',
    flag: '🇪🇸',
  },
  {
    label: 'CA',
    value: 'ca-ES',
    flag: 'ca',
    svg: true,
  },
  {
    label: 'EN',
    value: 'en-ES',
    flag: '🇬🇧',
  },
];

const languagesWithFlagsFR: Array<LanguageWithFlag> = [
  {
    label: 'FR',
    value: 'fr-FR',
    flag: '🇫🇷',
  },
  {
    label: 'EN',
    value: 'en-FR',
    flag: '🇬🇧',
  },
];

const languagesWithFlagsPT: Array<LanguageWithFlag> = [
  {
    label: 'PT',
    value: 'pt-PT',
    flag: '🇵🇹',
  },
  {
    label: 'EN',
    value: 'en-PT',
    flag: '🇬🇧',
  },
];

export const languagesWithFlags: Record<string, LanguageWithFlag[]> = {
  ES: languagesWithFlagsES,
  FR: languagesWithFlagsFR,
  PT: languagesWithFlagsPT,
};

export const countriesWelcomePage: { label: string; value: AvailableCountries }[] = [
  {
    label: 'Spain',
    value: 'ES',
  },
  {
    label: 'Portugal',
    value: 'PT',
  },
  {
    label: 'France',
    value: 'FR',
  },
];

export const languagesWelcomePage: Record<AvailableCountries, { label: string; value: string }[]> =
  {
    ES: [
      {
        label: 'Spanish',
        value: '',
      },
      {
        label: 'English',
        value: 'en-ES',
      },
      {
        label: 'Catalan',
        value: 'ca-ES',
      },
    ],
    FR: [
      {
        label: 'French',
        value: '',
      },
      {
        label: 'English',
        value: 'en-FR',
      },
    ],
    PT: [
      {
        label: 'Portuguese',
        value: '',
      },
      {
        label: 'English',
        value: 'en-PT',
      },
    ],
  };
