import Axios from 'axios';
import { CONTENT_LANGUAGE_HEADER } from '../config/constants';
import { ClikaliaApiClient } from '../models/clikaliaApiClient.interface';

const NOT_DEFINE_BASE_URL = 'http://CLIENT_NOT_INIT';
const apikey = process.env.NEXT_PUBLIC_API_KEY_CLIKALIA_WEBSITE;

const axiosClient = Axios.create({
  baseURL: NOT_DEFINE_BASE_URL,
  timeout: 650000,
  headers: {
    apikey,
  },
});

const init = (baseURL?: string) => {
  const currentBaseUrl = axiosClient.defaults.baseURL;

  if (!baseURL || currentBaseUrl === baseURL) return;

  axiosClient.defaults.baseURL = baseURL;
};

const setLocaleHeader = (locale?: string) => {
  if (locale) {
    axiosClient.defaults.headers[CONTENT_LANGUAGE_HEADER] = locale;
  }
};

const clikaliaApiClient = axiosClient as ClikaliaApiClient;
clikaliaApiClient.init = init;
clikaliaApiClient.setLocaleHeader = setLocaleHeader;

export default clikaliaApiClient;
