import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { SpinnerWrapper } from './ScreenLoadingFromRoute.styled';
import { Icon } from '@clikaliatech/clikalia-ui';

const ScreenLoadingFromRoute = () => {
  const [isLoading, setIsLoading] = useState(false);
  const timeoutRef = useRef<number>();
  const router = useRouter();

  const showScreenSpinner = () => {
    timeoutRef.current = window.setTimeout(() => {
      setIsLoading(true);
    }, 300);
  };

  const hideScreenSpinner = () => {
    window.clearTimeout(timeoutRef.current);
    setIsLoading(false);
  };

  useEffect(() => {
    router.events.on('routeChangeStart', showScreenSpinner);
    router.events.on('routeChangeComplete', hideScreenSpinner);

    return () => {
      router.events.off('routeChangeStart', showScreenSpinner);
      router.events.off('routeChangeComplete', hideScreenSpinner);
      window.clearTimeout(timeoutRef.current);
    };
  }, [router.events]);

  if (!isLoading) {
    return null;
  }

  return (
    <SpinnerWrapper>
      <Icon
        name="spinner"
        animation="fa-spin"
        variant={Icon.Variants.REGULAR}
        color={Icon.Colors.PRIMARY}
      />
    </SpinnerWrapper>
  );
};

export default ScreenLoadingFromRoute;
