import { esRouting, frRouting, ptRouting } from '@modules/acquisitions/routes';
import { useRouter } from 'next/router';
import { AvailableCountries, AvailableLanguages } from 'cross-config/localization/config';
import { getCountryCode } from 'cross-config/localization/utils';

const routesPerCountry: Record<
  AvailableCountries,
  typeof esRouting | typeof frRouting | typeof ptRouting
> = {
  ES: esRouting,
  FR: frRouting,
  PT: ptRouting,
};

const useRoutesI18n = () => {
  return routesPerCountry[getCountryCode()];
};

export const basePathRoutes: Record<AvailableLanguages, string> = {
  'es-ES': 'formulario',
  'ca-ES': 'formulario',
  'en-ES': 'formulario',
  'fr-FR': 'formulaire',
  'en-FR': 'formulaire',
  'pt-PT': 'formulario',
  'en-PT': 'formulario',
};

export const useRouteOutsideForm = () => {
  const { push } = useRouter();
  const handleRoute = (route: string) => {
    window && window.dataLayer && window.dataLayer.push({ event: 'pageview' });
    push(route, undefined, { shallow: true });
  };

  return handleRoute;
};

export default useRoutesI18n;
