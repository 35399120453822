// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setPropertyRecursive = <T extends Record<string, any>, R>(
  path: string,
  value: R,
  obj?: T
): T => {
  const [head, ...rest] = path.split('.');

  if (!!obj) {
    const noRestValue = typeof value === 'object' ? { ...obj[head], ...value } : value;
    const propertyValue = rest.length
      ? setPropertyRecursive(rest.join('.'), value, obj[head])
      : noRestValue;

    return {
      ...obj,
      [head]: propertyValue,
    };
  }
  return { [head]: rest.length ? setPropertyRecursive(rest.join('.'), value) : value } as T;
};
