import Axios from 'axios';
import type { AxiosInstance, AxiosError } from 'axios';

import {
  ClikaliaApiError,
  DefaultRequestData,
  GetRequest,
  RestOfRequest,
  Request,
} from '../../models/clikaliaAuthApiClient.interface';
import { API_ERRORS } from './ClikaliaApiErrors';
import { CONTENT_LANGUAGE_HEADER } from '../../config/constants';

class ClikaliaAuthApiClient {
  private readonly axiosInstance: AxiosInstance;

  constructor(baseURL: string) {
    this.axiosInstance = Axios.create({ baseURL, withCredentials: true, timeout: 650000 });
  }

  private async request<T, S = DefaultRequestData>(request: Request<S>): Promise<T> {
    try {
      const response = await this.axiosInstance.request<T>(request);

      return response.data;
    } catch (error) {
      const requestError = error as AxiosError<ClikaliaApiError>;

      if (requestError.response) {
        // Api error response
        const { message } = requestError.response.data;

        if (message.includes(API_ERRORS.EXPIRED_ACCESS_TOKEN_MESSAGE)) {
          try {
            await this.axiosInstance.request({
              method: 'POST',
              url: '/user/refresh-token',
              data: {},
            });
          } catch {
            this.notifyInvalidToken();
            throw new Error('refreshTokenRequestError');
          }
          return await this.request(request);
        }
        if (
          message.includes(API_ERRORS.INVALID_ACCESS_TOKEN_MESSAGE) ||
          message.includes(API_ERRORS.INVALID_REFRESH_TOKEN_MESSAGE) ||
          message.includes(API_ERRORS.MISSING_ACCESS_TOKEN_MESSAGE)
        ) {
          this.notifyInvalidToken();
        }

        throw new Error(message);
      } else if (requestError.request) {
        // Timeout error response
        throw new Error(`Timeout error: ${requestError.message}`);
      } else {
        // Unknown error
        throw new Error(`Unknown error: ${requestError.message}`);
      }
    }
  }

  public async get<T, S = DefaultRequestData>(request: Omit<GetRequest<S>, 'method'>) {
    return await this.request<T, S>({ method: 'GET', ...request });
  }

  public async post<T, S = DefaultRequestData>(request: Omit<RestOfRequest<S>, 'method'>) {
    return await this.request<T, S>({ method: 'POST', ...request });
  }

  public async put<T, S = DefaultRequestData>(request: Omit<RestOfRequest<S>, 'method'>) {
    return await this.request<T, S>({ method: 'PUT', ...request });
  }

  public async delete<T, S = DefaultRequestData>(request: Omit<RestOfRequest<S>, 'method'>) {
    return await this.request<T, S>({ method: 'DELETE', ...request });
  }

  public setLocaleHeader(locale?: string) {
    if (locale) {
      this.axiosInstance.defaults.headers[CONTENT_LANGUAGE_HEADER] = locale;
    }
  }

  private notifyInvalidToken() {
    const authEvent = new CustomEvent('invalid-auth-token');
    document.dispatchEvent(authEvent);
  }
}

export default ClikaliaAuthApiClient;
