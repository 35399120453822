const localesByCountry = {
  ES: ['es-ES', 'ca-ES', 'en-ES'],
  FR: ['fr-FR', 'en-FR'],
  PT: ['pt-PT', 'en-PT'],
};

const countryCode = process.env.NEXT_PUBLIC_COUNTRY_CODE || 'ES';

module.exports = {
  i18n: {
    defaultLocale: localesByCountry[countryCode][0],
    locales: [...localesByCountry[countryCode]],
    localeDetection: false,
  },
  fallbackLng: {
    'ca-ES': ['es-CA'],
    'en-ES': ['es-EN'],
    'en-FR': ['fr-EN'],
    'en-PT': ['pt-EN'],
  },
  nonExplicitSupportedLngs: true,
};
