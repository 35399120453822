import styled from 'styled-components';

export const SpinnerWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  fontSize: theme.size(4),
  backgroundColor: theme.palette.white,
  opacity: 0.7,
  zIndex: 100,
}));
